import React from 'react'

const Instruction = ({ heading, fileName, standardFile }) => {
  return (
    <div>
      <h4 className="mb-2 text-2xl font-bold text-gray-900">{heading}</h4>
      <ul className="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          Export <span className="font-bold">{fileName}.csv</span> file from the
          website
        </li>
        <li>Make necessary modifications to the file</li>
        <li>Upload the modified file for verification</li>
        <li>Once verified, an automated compressed file will be downloaded</li>
        <li>Upload the files from the compressed file to the website</li>
      </ul>

      <h4 className="mt-6 mb-2 text-2xl font-bold text-gray-900 mt-">
        File Validation Instructions
      </h4>
      <ul className="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          Download the{' '}
          <a
            href={standardFile}
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          >
            standard file
          </a>{' '}
          for reference. If you encounter errors during validation, please
          modify your file according to this format.
        </li>
        <li>
          Validate rules:
          <ul className="space-y-1 ps-5">
            <li className="flex items-center space-x-3 rtl:space-x-reverse">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span>Wrong file type different from format UTF-8</span>
            </li>
            <li className="flex items-center space-x-3 rtl:space-x-reverse">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span>All header fields should exist</span>
            </li>
            <li className="flex items-center space-x-3 rtl:space-x-reverse">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span>No special character for value</span>
            </li>
            <li className="flex items-center space-x-3 rtl:space-x-reverse">
              <svg
                className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span>
                Each field match pre-define format such as no $ for number, text
                is text, no missing value for ID etc
              </span>
            </li>
          </ul>
        </li>
        <li>
          If the validation fails, the errors will be listed. For example, "Row
          2 - FullColour Tag: Value is not a Y or N."
        </li>
        <li>
          If the validation passes, the message will be "Success, File is valid"
          and the compressed file will be downloaded
        </li>
      </ul>
    </div>
  )
}

export default Instruction

import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import Papa from 'papaparse'
import { useState } from 'react'
import Instruction from './Instruction'
import Loading from './Loading'

function App() {
  const [loading, setLoading] = useState(false)
  const [auSuccess, setAuSuccess] = useState(false)
  const [usaSuccess, setUsaSuccess] = useState(false)
  const [auErrorList, setAuErrorList] = useState([])
  const [usaErrorList, setUsaErrorList] = useState([])

  const onFileChange = (event) => {
    const { name, files } = event.target
    setLoading(true)
    setAuErrorList([])
    setUsaErrorList([])
    setAuSuccess(false)
    setUsaSuccess(false)
    const file = files[0]

    if (file) {
      if (file.type === 'text/csv') {
        const reader = new FileReader()
        reader.onload = (e) => {
          const arrayBuffer = e.target.result
          const uint8Array = new Uint8Array(arrayBuffer)
          const utf8Decoder = new TextDecoder('utf-8', { fatal: true })
          try {
            const content = utf8Decoder.decode(uint8Array)
          } catch (e) {
            if (name === 'au') {
              setAuErrorList([
                {
                  title: 'File encoding error',
                  description: 'File encoding is not UTF-8',
                },
              ])
            } else if (name === 'usa') {
              setUsaErrorList([
                {
                  title: 'File encoding error',
                  description: 'File encoding is not UTF-8',
                },
              ])
            }
            event.target.value = null
            setLoading(false)
            return
          }
          Papa.parse(file, {
            skipEmptyLines: true,
            complete: function (results) {
              let errorOnCheck = false
              if (results.data.length > 0) {
                const header = results.data[0].map((item) =>
                  item.replace(/"/g, '')
                )
                const checkErrorList = checkFileHeader(header, name).concat(
                  checkRequiredFields(header, results.data, name)
                )
                if (checkErrorList.length > 0) {
                  errorOnCheck = true
                  if (name === 'au') {
                    setAuErrorList((prev) => [...prev, ...checkErrorList])
                  } else if (name === 'usa') {
                    setUsaErrorList((prev) => [...prev, ...checkErrorList])
                  }
                }
                if (!errorOnCheck) {
                  downloadFile(results.data[0], results.data.slice(1), name)
                }
                setLoading(false)
              } else {
                if (name === 'au') {
                  setAuErrorList([
                    {
                      title: 'File error',
                      description: 'Empty CSV file or unable to read header',
                    },
                  ])
                } else if (name === 'usa') {
                  setUsaErrorList([
                    {
                      title: 'File error',
                      description: 'Empty CSV file or unable to read header',
                    },
                  ])
                }
                setLoading(false)
              }
            },
            error: function (error) {
              if (name === 'au') {
                setAuErrorList([
                  {
                    title: 'File error',
                    description: 'Unable to read file',
                  },
                ])
              } else if (name === 'usa') {
                setUsaErrorList([
                  {
                    title: 'File error',
                    description: 'Unable to read file',
                  },
                ])
              }
            },
          })
        }
        reader.readAsArrayBuffer(file)
      } else {
        if (name === 'au') {
          setAuErrorList([
            {
              title: 'File format error',
              description: 'Please select csv file',
            },
          ])
        } else if (name === 'usa') {
          setUsaErrorList([
            {
              title: 'File format error',
              description: 'Please select csv file',
            },
          ])
        }
        setLoading(false)
      }
    } else {
      if (name === 'au') {
        setAuErrorList([
          { title: 'File error', description: 'No file selected' },
        ])
      } else if (name === 'usa') {
        setUsaErrorList([
          { title: 'File error', description: 'No file selected' },
        ])
      }
      setLoading(false)
    }
    event.target.value = null
  }

  const checkFileHeader = (filerHeader, name) => {
    let correctHeader = []
    if (name === 'au') {
      correctHeader = [
        'Product Code',
        'Product Name',
        'Parent Category',
        'Child Category_1',
        'Child Category_2',
        'Child Category_3',
        'AdditionalCategories',
        'APPA Categories',
        'Product Description',
        'Product Additional Information',
        'Item Colour Name_1',
        'Item Colour Range_1',
        'Item Colour Name_2',
        'Item Colour Range_2',
        'Item Colour Name_3',
        'Item Colour Range_3',
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Date of last update',
        'Item Size Name_1',
        'Item Size Dimensions_1',
        'Item Size Name_2',
        'Item Size Dimensions_2',
        'Item Size Name_3',
        'Item Size Dimensions_3',
        'Product Line Drawing File Names',
        'Product Certificate File Names',
        'Product Photo File Names',
        'Decoration Type_1',
        'Decoration Type_1 Print Size_1',
        'Decoration Type_2',
        'Decoration Type_2 Print Size_2',
        'Decoration Type_3',
        'Decoration Type_3 Print Size_3',
        'Decoration Type_4',
        'Decoration Type_4 Print Size_4',
        'Decoration Type_5',
        'Decoration Type_5 Print Size_5',
        'Decoration Type_6',
        'Decoration Type_6 Print Size_6',
        'Decoration Type_7',
        'Decoration Type_7 Print Size_7',
        'Decoration Type_8',
        'Decoration Type_8 Print Size_8',
        'Packaging',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'BlankPriceLocalDes1',
        'Local 1 NZ Rate',
        'Local 1 Is NZ Price Table',
        'QtyLocal1_1',
        'PriceLocal1_1',
        'QtyLocal1_2',
        'PriceLocal1_2',
        'QtyLocal1_3',
        'PriceLocal1_3',
        'QtyLocal1_4',
        'PriceLocal1_4',
        'QtyLocal1_5',
        'PriceLocal1_5',
        'QtyLocal1_6',
        'PriceLocal1_6',
        'QtyLocal1_7',
        'PriceLocal1_7',
        'QtyLocal1_8',
        'PriceLocal1_8',
        'QtyLocal1_9',
        'PriceLocal1_9',
        'BlankPriceLocalDes2',
        'Local 2 NZ Rate',
        'Local 2 Is NZ Price Table',
        'QtyLocal2_1',
        'PriceLocal2_1',
        'QtyLocal2_2',
        'PriceLocal2_2',
        'QtyLocal2_3',
        'PriceLocal2_3',
        'QtyLocal2_4',
        'PriceLocal2_4',
        'QtyLocal2_5',
        'PriceLocal2_5',
        'QtyLocal2_6',
        'PriceLocal2_6',
        'QtyLocal2_7',
        'PriceLocal2_7',
        'QtyLocal2_8',
        'PriceLocal2_8',
        'QtyLocal2_9',
        'PriceLocal2_9',
        'BlankPriceLocalDes3',
        'Local 3 NZ Rate',
        'Local 3 Is NZ Price Table',
        'QtyLocal3_1',
        'PriceLocal3_1',
        'QtyLocal3_2',
        'PriceLocal3_2',
        'QtyLocal3_3',
        'PriceLocal3_3',
        'QtyLocal3_4',
        'PriceLocal3_4',
        'QtyLocal3_5',
        'PriceLocal3_5',
        'QtyLocal3_6',
        'PriceLocal3_6',
        'QtyLocal3_7',
        'PriceLocal3_7',
        'QtyLocal3_8',
        'PriceLocal3_8',
        'QtyLocal3_9',
        'PriceLocal3_9',
        'Service0',
        'Service 0 NZ Rate',
        'Service0Desc1',
        'Service0Cost1',
        'Service0MoQ1',
        'Service0MaxQty1',
        'Service0Desc2',
        'Service0Cost2',
        'Service0MoQ2',
        'Service0MaxQty2',
        'Service0Desc3',
        'Service0Cost3',
        'Service0MoQ3',
        'Service0MaxQty3',
        'Service0Desc4',
        'Service0Cost4',
        'Service0MoQ4',
        'Service0MaxQty4',
        'Service0Desc5',
        'Service0Cost5',
        'Service0MoQ5',
        'Service0MaxQty5',
        'Service0Desc6',
        'Service0Cost6',
        'Service0MoQ6',
        'Service0MaxQty6',
        'Service1',
        'Service 1 NZ Rate',
        'Service1Desc1',
        'Service1Cost1',
        'Service1MoQ1',
        'Service1MaxQty1',
        'Service1Desc2',
        'Service1Cost2',
        'Service1MoQ2',
        'Service1MaxQty2',
        'Service1Desc3',
        'Service1Cost3',
        'Service1MoQ3',
        'Service1MaxQty3',
        'Service1Desc4',
        'Service1Cost4',
        'Service1MoQ4',
        'Service1MaxQty4',
        'Service1Desc5',
        'Service1Cost5',
        'Service1MoQ5',
        'Service1MaxQty5',
        'Service1Desc6',
        'Service1Cost6',
        'Service1MoQ6',
        'Service1MaxQty6',
        'Service2',
        'Service 2 NZ Rate',
        'Service2Desc1',
        'Service2Cost1',
        'Service2MoQ1',
        'Service2MaxQty1',
        'Service2Desc2',
        'Service2Cost2',
        'Service2MoQ2',
        'Service2MaxQty2',
        'Service2Desc3',
        'Service2Cost3',
        'Service2MoQ3',
        'Service2MaxQty3',
        'Service2Desc4',
        'Service2Cost4',
        'Service2MoQ4',
        'Service2MaxQty4',
        'Service2Desc5',
        'Service2Cost5',
        'Service2MoQ5',
        'Service2MaxQty5',
        'Service2Desc6',
        'Service2Cost6',
        'Service2MoQ6',
        'Service2MaxQty6',
        'Service3',
        'Service 3 NZ Rate',
        'Service3Desc1',
        'Service3Cost1',
        'Service3MoQ1',
        'Service3MaxQty1',
        'Service3Desc2',
        'Service3Cost2',
        'Service3MoQ2',
        'Service3MaxQty2',
        'Service3Desc3',
        'Service3Cost3',
        'Service3MoQ3',
        'Service3MaxQty3',
        'Service3Desc4',
        'Service3Cost4',
        'Service3MoQ4',
        'Service3MaxQty4',
        'Service3Desc5',
        'Service3Cost5',
        'Service3MoQ5',
        'Service3MaxQty5',
        'Service3Desc6',
        'Service3Cost6',
        'Service3MoQ6',
        'Service3MaxQty6',
        'Service4',
        'Service 4 NZ Rate',
        'Service4Desc1',
        'Service4Cost1',
        'Service4MoQ1',
        'Service4MaxQty1',
        'Service4Desc2',
        'Service4Cost2',
        'Service4MoQ2',
        'Service4MaxQty2',
        'Service4Desc3',
        'Service4Cost3',
        'Service4MoQ3',
        'Service4MaxQty3',
        'Service4Desc4',
        'Service4Cost4',
        'Service4MoQ4',
        'Service4MaxQty4',
        'Service4Desc5',
        'Service4Cost5',
        'Service4MoQ5',
        'Service4MaxQty5',
        'Service4Desc6',
        'Service4Cost6',
        'Service4MoQ6',
        'Service4MaxQty6',
        'Service5',
        'Service 5 NZ Rate',
        'Service5Desc1',
        'Service5Cost1',
        'Service5MoQ1',
        'Service5MaxQty1',
        'Service5Desc2',
        'Service5Cost2',
        'Service5MoQ2',
        'Service5MaxQty2',
        'Service5Desc3',
        'Service5Cost3',
        'Service5MoQ3',
        'Service5MaxQty3',
        'Service5Desc4',
        'Service5Cost4',
        'Service5MoQ4',
        'Service5MaxQty4',
        'Service5Desc5',
        'Service5Cost5',
        'Service5MoQ5',
        'Service5MaxQty5',
        'Service5Desc6',
        'Service5Cost6',
        'Service5MoQ6',
        'Service5MaxQty6',
        'OptionalService1Desc',
        'OptionalService1Cost',
        'OptionalService1 NZ Rate',
        'OptionalService1MOQ',
        'OptionalService1MaxQty',
        'OptionalService2Desc',
        'OptionalService2Cost',
        'OptionalService2 NZ Rate',
        'OptionalService2MOQ',
        'OptionalService2MaxQty',
        'OptionalService3Desc',
        'OptionalService3Cost',
        'OptionalService3 NZ Rate',
        'OptionalService3MOQ',
        'OptionalService3MaxQty',
        'BlankPriceOSDes1',
        'BlankPriceOSDes1 NZ Rate',
        'BlankPriceOSDes1 Is NZ Price Table',
        'QtyOS1_1',
        'PriceOS1_1',
        'QtyOS1_2',
        'PriceOS1_2',
        'QtyOS1_3',
        'PriceOS1_3',
        'QtyOS1_4',
        'PriceOS1_4',
        'QtyOS1_5',
        'PriceOS1_5',
        'QtyOS1_6',
        'PriceOS1_6',
        'QtyOS1_7',
        'PriceOS1_7',
        'QtyOS1_8',
        'PriceOS1_8',
        'QtyOS1_9',
        'PriceOS1_9',
        'Service6',
        'Service 6 NZ Rate',
        'Service6Desc1',
        'Service6Cost1',
        'Service6MoQ1',
        'Service6MaxQty1',
        'Service6Desc2',
        'Service6Cost2',
        'Service6MoQ2',
        'Service6MaxQty2',
        'Service6Desc3',
        'Service6Cost3',
        'Service6MoQ3',
        'Service6MaxQty3',
        'Service6Desc4',
        'Service6Cost4',
        'Service6MoQ4',
        'Service6MaxQty4',
        'Service6Desc5',
        'Service6Cost5',
        'Service6MoQ5',
        'Service6MaxQty5',
        'Service6Desc6',
        'Service6Cost6',
        'Service6MoQ6',
        'Service6MaxQty6',
        'BlankPriceOSDes2',
        'BlankPriceOSDes2 NZ Rate',
        'BlankPriceOSDes2 Is NZ Price Table',
        'QtyOS2_1',
        'PriceOS2_1',
        'QtyOS2_2',
        'PriceOS2_2',
        'QtyOS2_3',
        'PriceOS2_3',
        'QtyOS2_4',
        'PriceOS2_4',
        'QtyOS2_5',
        'PriceOS2_5',
        'QtyOS2_6',
        'PriceOS2_6',
        'QtyOS2_7',
        'PriceOS2_7',
        'QtyOS2_8',
        'PriceOS2_8',
        'QtyOS2_9',
        'PriceOS2_9',
        'Service7',
        'Service 7 NZ Rate',
        'Service7Desc1',
        'Service7Cost1',
        'Service7MoQ1',
        'Service7MaxQty1',
        'Service7Desc2',
        'Service7Cost2',
        'Service7MoQ2',
        'Service7MaxQty2',
        'Service7Desc3',
        'Service7Cost3',
        'Service7MoQ3',
        'Service7MaxQty3',
        'Service7Desc4',
        'Service7Cost4',
        'Service7MoQ4',
        'Service7MaxQty4',
        'Service7Desc5',
        'Service7Cost5',
        'Service7MoQ5',
        'Service7MaxQty5',
        'Service7Desc6',
        'Service7Cost6',
        'Service7MoQ6',
        'Service7MaxQty6',
        'BlankPriceOSDes3',
        'BlankPriceOSDes3 NZ Rate',
        'BlankPriceOSDes3 Is NZ Price Table',
        'QtyOS3_1',
        'PriceOS3_1',
        'QtyOS3_2',
        'PriceOS3_2',
        'QtyOS3_3',
        'PriceOS3_3',
        'QtyOS3_4',
        'PriceOS3_4',
        'QtyOS3_5',
        'PriceOS3_5',
        'QtyOS3_6',
        'PriceOS3_6',
        'QtyOS3_7',
        'PriceOS3_7',
        'QtyOS3_8',
        'PriceOS3_8',
        'QtyOS3_9',
        'PriceOS3_9',
        'Service8',
        'Service 8 NZ Rate',
        'Service8Desc1',
        'Service8Cost1',
        'Service8MoQ1',
        'Service8MaxQty1',
        'Service8Desc2',
        'Service8Cost2',
        'Service8MoQ2',
        'Service8MaxQty2',
        'Service8Desc3',
        'Service8Cost3',
        'Service8MoQ3',
        'Service8MaxQty3',
        'Service8Desc4',
        'Service8Cost4',
        'Service8MoQ4',
        'Service8MaxQty4',
        'Service8Desc5',
        'Service8Cost5',
        'Service8MoQ5',
        'Service8MaxQty5',
        'Service8Desc6',
        'Service8Cost6',
        'Service8MoQ6',
        'Service8MaxQty6',
        'BlankPriceOSDes4',
        'BlankPriceOSDes4 NZ Rate',
        'BlankPriceOSDes4 Is NZ Price Table',
        'QtyOS4_1',
        'PriceOS4_1',
        'QtyOS4_2',
        'PriceOS4_2',
        'QtyOS4_3',
        'PriceOS4_3',
        'QtyOS4_4',
        'PriceOS4_4',
        'QtyOS4_5',
        'PriceOS4_5',
        'QtyOS4_6',
        'PriceOS4_6',
        'QtyOS4_7',
        'PriceOS4_7',
        'QtyOS4_8',
        'PriceOS4_8',
        'QtyOS4_9',
        'PriceOS4_9',
        'Service9',
        'Service 9 NZ Rate',
        'Service9Desc1',
        'Service9Cost1',
        'Service9MoQ1',
        'Service9MaxQty1',
        'Service9Desc2',
        'Service9Cost2',
        'Service9MoQ2',
        'Service9MaxQty2',
        'Service9Desc3',
        'Service9Cost3',
        'Service9MoQ3',
        'Service9MaxQty3',
        'Service9Desc4',
        'Service9Cost4',
        'Service9MoQ4',
        'Service9MaxQty4',
        'Service9Desc5',
        'Service9Cost5',
        'Service9MoQ5',
        'Service9MaxQty5',
        'Service9Desc6',
        'Service9Cost6',
        'Service9MoQ6',
        'Service9MaxQty6',
        'OptionalService4Desc',
        'OptionalService4Cost',
        'OptionalService4 NZ Rate',
        'OptionalService4MOQ',
        'OptionalService4MaxQty',
        'OptionalService5Desc',
        'OptionalService5Cost',
        'OptionalService5 NZ Rate',
        'OptionalService5MOQ',
        'OptionalService5MaxQty',
        'OptionalService6Desc',
        'OptionalService6Cost',
        'OptionalService6 NZ Rate',
        'OptionalService6MOQ',
        'OptionalService6MaxQty',
        'New Setup Cost',
        'Repeat Setup Cost',
        'Price Disclaimer',
        'Product Stock',
        'Freight Details',
        'NZ Freight',
        'Product Change Log',
        'Product Change Log NZ',
        'NZ Tag',
        'NZ Freight Rate',
        'NZ Setup Rate',
      ]
    } else if (name === 'usa') {
      correctHeader = [
        'Product Code',
        'Product Name',
        'Parent Category',
        'Child Category_1',
        'Child Category_2',
        'Child Category_3',
        'AdditionalCategories',
        'Product Description',
        'Product Additional Information',
        'Item Colour Name_1',
        'Item Colour Range_1',
        'Item Colour Name_2',
        'Item Colour Range_2',
        'Item Colour Name_3',
        'Item Colour Range_3',
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Item Size Name_1',
        'Item Size Dimensions_1',
        'Item Size Name_2',
        'Item Size Dimensions_2',
        'Item Size Name_3',
        'Item Size Dimensions_3',
        'Product Line Drawing File Names',
        'Product Certificate File Names',
        'Product Photo File Names',
        'Decoration Type_1',
        'Decoration Type_1 Print Size_1',
        'Decoration Type_2',
        'Decoration Type_2 Print Size_2',
        'Decoration Type_3',
        'Decoration Type_3 Print Size_3',
        'Decoration Type_4',
        'Decoration Type_4 Print Size_4',
        'Decoration Type_5',
        'Decoration Type_5 Print Size_5',
        'Decoration Type_6',
        'Decoration Type_6 Print Size_6',
        'Decoration Type_7',
        'Decoration Type_7 Print Size_7',
        'Decoration Type_8',
        'Decoration Type_8 Print Size_8',
        'Packaging',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'BlankPriceLocalDes1',
        'QtyLocal1_1',
        'PriceLocal1_1',
        'QtyLocal1_2',
        'PriceLocal1_2',
        'QtyLocal1_3',
        'PriceLocal1_3',
        'QtyLocal1_4',
        'PriceLocal1_4',
        'QtyLocal1_5',
        'PriceLocal1_5',
        'QtyLocal1_6',
        'PriceLocal1_6',
        'QtyLocal1_7',
        'PriceLocal1_7',
        'QtyLocal1_8',
        'PriceLocal1_8',
        'QtyLocal1_9',
        'PriceLocal1_9',
        'BlankPriceLocalDes2',
        'QtyLocal2_1',
        'PriceLocal2_1',
        'QtyLocal2_2',
        'PriceLocal2_2',
        'QtyLocal2_3',
        'PriceLocal2_3',
        'QtyLocal2_4',
        'PriceLocal2_4',
        'QtyLocal2_5',
        'PriceLocal2_5',
        'QtyLocal2_6',
        'PriceLocal2_6',
        'QtyLocal2_7',
        'PriceLocal2_7',
        'QtyLocal2_8',
        'PriceLocal2_8',
        'QtyLocal2_9',
        'PriceLocal2_9',
        'BlankPriceLocalDes3',
        'QtyLocal3_1',
        'PriceLocal3_1',
        'QtyLocal3_2',
        'PriceLocal3_2',
        'QtyLocal3_3',
        'PriceLocal3_3',
        'QtyLocal3_4',
        'PriceLocal3_4',
        'QtyLocal3_5',
        'PriceLocal3_5',
        'QtyLocal3_6',
        'PriceLocal3_6',
        'QtyLocal3_7',
        'PriceLocal3_7',
        'QtyLocal3_8',
        'PriceLocal3_8',
        'QtyLocal3_9',
        'PriceLocal3_9',
        'Service1',
        'Service1Desc1',
        'Service1Cost1',
        'Service1MoQ1',
        'Service1MaxQty1',
        'Service1Desc2',
        'Service1Cost2',
        'Service1MoQ2',
        'Service1MaxQty2',
        'Service1Desc3',
        'Service1Cost3',
        'Service1MoQ3',
        'Service1MaxQty3',
        'Service1Desc4',
        'Service1Cost4',
        'Service1MoQ4',
        'Service1MaxQty4',
        'Service1Desc5',
        'Service1Cost5',
        'Service1MoQ5',
        'Service1MaxQty5',
        'Service1Desc6',
        'Service1Cost6',
        'Service1MoQ6',
        'Service1MaxQty6',
        'Service2',
        'Service2Desc1',
        'Service2Cost1',
        'Service2MoQ1',
        'Service2MaxQty1',
        'Service2Desc2',
        'Service2Cost2',
        'Service2MoQ2',
        'Service2MaxQty2',
        'Service2Desc3',
        'Service2Cost3',
        'Service2MoQ3',
        'Service2MaxQty3',
        'Service2Desc4',
        'Service2Cost4',
        'Service2MoQ4',
        'Service2MaxQty4',
        'Service2Desc5',
        'Service2Cost5',
        'Service2MoQ5',
        'Service2MaxQty5',
        'Service2Desc6',
        'Service2Cost6',
        'Service2MoQ6',
        'Service2MaxQty6',
        'Service3',
        'Service3Desc1',
        'Service3Cost1',
        'Service3MoQ1',
        'Service3MaxQty1',
        'Service3Desc2',
        'Service3Cost2',
        'Service3MoQ2',
        'Service3MaxQty2',
        'Service3Desc3',
        'Service3Cost3',
        'Service3MoQ3',
        'Service3MaxQty3',
        'Service3Desc4',
        'Service3Cost4',
        'Service3MoQ4',
        'Service3MaxQty4',
        'Service3Desc5',
        'Service3Cost5',
        'Service3MoQ5',
        'Service3MaxQty5',
        'Service3Desc6',
        'Service3Cost6',
        'Service3MoQ6',
        'Service3MaxQty6',
        'Service4',
        'Service4Desc1',
        'Service4Cost1',
        'Service4MoQ1',
        'Service4MaxQty1',
        'Service4Desc2',
        'Service4Cost2',
        'Service4MoQ2',
        'Service4MaxQty2',
        'Service4Desc3',
        'Service4Cost3',
        'Service4MoQ3',
        'Service4MaxQty3',
        'Service4Desc4',
        'Service4Cost4',
        'Service4MoQ4',
        'Service4MaxQty4',
        'Service4Desc5',
        'Service4Cost5',
        'Service4MoQ5',
        'Service4MaxQty5',
        'Service4Desc6',
        'Service4Cost6',
        'Service4MoQ6',
        'Service4MaxQty6',
        'OptionalService1Desc',
        'OptionalService1Cost',
        'OptionalService1MOQ',
        'OptionalService1MaxQty',
        'OptionalService2Desc',
        'OptionalService2Cost',
        'OptionalService2MOQ',
        'OptionalService2MaxQty',
        'OptionalService3Desc',
        'OptionalService3Cost',
        'OptionalService3MOQ',
        'OptionalService3MaxQty',
        'BlankPriceOSDes1',
        'QtyOS1_1',
        'PriceOS1_1',
        'QtyOS1_2',
        'PriceOS1_2',
        'QtyOS1_3',
        'PriceOS1_3',
        'QtyOS1_4',
        'PriceOS1_4',
        'QtyOS1_5',
        'PriceOS1_5',
        'QtyOS1_6',
        'PriceOS1_6',
        'QtyOS1_7',
        'PriceOS1_7',
        'QtyOS1_8',
        'PriceOS1_8',
        'QtyOS1_9',
        'PriceOS1_9',
        'Service5',
        'Service5Desc1',
        'Service5Cost1',
        'Service5MoQ1',
        'Service5MaxQty1',
        'Service5Desc2',
        'Service5Cost2',
        'Service5MoQ2',
        'Service5MaxQty2',
        'Service5Desc3',
        'Service5Cost3',
        'Service5MoQ3',
        'Service5MaxQty3',
        'Service5Desc4',
        'Service5Cost4',
        'Service5MoQ4',
        'Service5MaxQty4',
        'Service5Desc5',
        'Service5Cost5',
        'Service5MoQ5',
        'Service5MaxQty5',
        'Service5Desc6',
        'Service5Cost6',
        'Service5MoQ6',
        'Service5MaxQty6',
        'BlankPriceOSDes2',
        'QtyOS2_1',
        'PriceOS2_1',
        'QtyOS2_2',
        'PriceOS2_2',
        'QtyOS2_3',
        'PriceOS2_3',
        'QtyOS2_4',
        'PriceOS2_4',
        'QtyOS2_5',
        'PriceOS2_5',
        'QtyOS2_6',
        'PriceOS2_6',
        'QtyOS2_7',
        'PriceOS2_7',
        'QtyOS2_8',
        'PriceOS2_8',
        'QtyOS2_9',
        'PriceOS2_9',
        'Service6',
        'Service6Desc1',
        'Service6Cost1',
        'Service6MoQ1',
        'Service6MaxQty1',
        'Service6Desc2',
        'Service6Cost2',
        'Service6MoQ2',
        'Service6MaxQty2',
        'Service6Desc3',
        'Service6Cost3',
        'Service6MoQ3',
        'Service6MaxQty3',
        'Service6Desc4',
        'Service6Cost4',
        'Service6MoQ4',
        'Service6MaxQty4',
        'Service6Desc5',
        'Service6Cost5',
        'Service6MoQ5',
        'Service6MaxQty5',
        'Service6Desc6',
        'Service6Cost6',
        'Service6MoQ6',
        'Service6MaxQty6',
        'BlankPriceOSDes3',
        'QtyOS3_1',
        'PriceOS3_1',
        'QtyOS3_2',
        'PriceOS3_2',
        'QtyOS3_3',
        'PriceOS3_3',
        'QtyOS3_4',
        'PriceOS3_4',
        'QtyOS3_5',
        'PriceOS3_5',
        'QtyOS3_6',
        'PriceOS3_6',
        'QtyOS3_7',
        'PriceOS3_7',
        'QtyOS3_8',
        'PriceOS3_8',
        'QtyOS3_9',
        'PriceOS3_9',
        'Service7',
        'Service7Desc1',
        'Service7Cost1',
        'Service7MoQ1',
        'Service7MaxQty1',
        'Service7Desc2',
        'Service7Cost2',
        'Service7MoQ2',
        'Service7MaxQty2',
        'Service7Desc3',
        'Service7Cost3',
        'Service7MoQ3',
        'Service7MaxQty3',
        'Service7Desc4',
        'Service7Cost4',
        'Service7MoQ4',
        'Service7MaxQty4',
        'Service7Desc5',
        'Service7Cost5',
        'Service7MoQ5',
        'Service7MaxQty5',
        'Service7Desc6',
        'Service7Cost6',
        'Service7MoQ6',
        'Service7MaxQty6',
        'OptionalService4Desc',
        'OptionalService4Cost',
        'OptionalService4MOQ',
        'OptionalService4MaxQty',
        'OptionalService5Desc',
        'OptionalService5Cost',
        'OptionalService5MOQ',
        'OptionalService5MaxQty',
        'OptionalService6Desc',
        'OptionalService6Cost',
        'OptionalService6MOQ',
        'OptionalService6MaxQty',
        'New Setup Cost',
        'Repeat Setup Cost',
        'Price Disclaimer',
        'Product Stock',
        'Freight Details',
        'Product Change Log',
      ]
    }

    const missingHeader = []
    for (let i = 0; i < correctHeader.length; i++) {
      if (filerHeader.indexOf(correctHeader[i]) < 0) {
        missingHeader.push({
          title: 'File header error',
          description: `The file header miss the ${correctHeader[i]}`,
        })
      }
    }
    return missingHeader
  }

  const checkRequiredFields = (filerHeader, lines, name) => {
    let requiredFieldList = []
    let numberFieldList = []
    let booleanFieldList = []

    if (name === 'au') {
      requiredFieldList = [
        'Product Code',
        'Product Name',
        'Parent Category',
        'Child Category_1',
        'Product Description',
        'Product Additional Information',
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Product Photo File Names',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'New Setup Cost',
        'Repeat Setup Cost',
        'Price Disclaimer',
        'Freight Details',
        'NZ Freight',
        'NZ Tag',
        'NZ Freight Rate',
        'NZ Setup Rate',
      ]
      numberFieldList = [
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'Local 1 NZ Rate',
        'QtyLocal1_1',
        'PriceLocal1_1',
        'QtyLocal1_2',
        'PriceLocal1_2',
        'QtyLocal1_3',
        'PriceLocal1_3',
        'QtyLocal1_4',
        'PriceLocal1_4',
        'QtyLocal1_5',
        'PriceLocal1_5',
        'QtyLocal1_6',
        'PriceLocal1_6',
        'QtyLocal1_7',
        'PriceLocal1_7',
        'QtyLocal1_8',
        'PriceLocal1_8',
        'QtyLocal1_9',
        'PriceLocal1_9',
        'Local 2 NZ Rate',
        'QtyLocal2_1',
        'PriceLocal2_1',
        'QtyLocal2_2',
        'PriceLocal2_2',
        'QtyLocal2_3',
        'PriceLocal2_3',
        'QtyLocal2_4',
        'PriceLocal2_4',
        'QtyLocal2_5',
        'PriceLocal2_5',
        'QtyLocal2_6',
        'PriceLocal2_6',
        'QtyLocal2_7',
        'PriceLocal2_7',
        'QtyLocal2_8',
        'PriceLocal2_8',
        'QtyLocal2_9',
        'PriceLocal2_9',
        'Local 3 NZ Rate',
        'QtyLocal3_1',
        'PriceLocal3_1',
        'QtyLocal3_2',
        'PriceLocal3_2',
        'QtyLocal3_3',
        'PriceLocal3_3',
        'QtyLocal3_4',
        'PriceLocal3_4',
        'QtyLocal3_5',
        'PriceLocal3_5',
        'QtyLocal3_6',
        'PriceLocal3_6',
        'QtyLocal3_7',
        'PriceLocal3_7',
        'QtyLocal3_8',
        'PriceLocal3_8',
        'QtyLocal3_9',
        'PriceLocal3_9',
        'Service 0 NZ Rate',
        'Service0Cost1',
        'Service0MoQ1',
        'Service0MaxQty1',
        'Service0Cost2',
        'Service0MoQ2',
        'Service0MaxQty2',
        'Service0Cost3',
        'Service0MoQ3',
        'Service0MaxQty3',
        'Service0Cost4',
        'Service0MoQ4',
        'Service0MaxQty4',
        'Service0Cost5',
        'Service0MoQ5',
        'Service0MaxQty5',
        'Service0Cost6',
        'Service0MoQ6',
        'Service0MaxQty6',
        'Service 1 NZ Rate',
        'Service1Cost1',
        'Service1MoQ1',
        'Service1MaxQty1',
        'Service1Cost2',
        'Service1MoQ2',
        'Service1MaxQty2',
        'Service1Cost3',
        'Service1MoQ3',
        'Service1MaxQty3',
        'Service1Cost4',
        'Service1MoQ4',
        'Service1MaxQty4',
        'Service1Cost5',
        'Service1MoQ5',
        'Service1MaxQty5',
        'Service1Cost6',
        'Service1MoQ6',
        'Service1MaxQty6',
        'Service 2 NZ Rate',
        'Service2Cost1',
        'Service2MoQ1',
        'Service2MaxQty1',
        'Service2Cost2',
        'Service2MoQ2',
        'Service2MaxQty2',
        'Service2Cost3',
        'Service2MoQ3',
        'Service2MaxQty3',
        'Service2Cost4',
        'Service2MoQ4',
        'Service2MaxQty4',
        'Service2Cost5',
        'Service2MoQ5',
        'Service2MaxQty5',
        'Service2Cost6',
        'Service2MoQ6',
        'Service2MaxQty6',
        'Service 3 NZ Rate',
        'Service3Cost1',
        'Service3MoQ1',
        'Service3MaxQty1',
        'Service3Cost2',
        'Service3MoQ2',
        'Service3MaxQty2',
        'Service3Cost3',
        'Service3MoQ3',
        'Service3MaxQty3',
        'Service3Cost4',
        'Service3MoQ4',
        'Service3MaxQty4',
        'Service3Cost5',
        'Service3MoQ5',
        'Service3MaxQty5',
        'Service3Cost6',
        'Service3MoQ6',
        'Service3MaxQty6',
        'Service 4 NZ Rate',
        'Service4Cost1',
        'Service4MoQ1',
        'Service4MaxQty1',
        'Service4Cost2',
        'Service4MoQ2',
        'Service4MaxQty2',
        'Service4Cost3',
        'Service4MoQ3',
        'Service4MaxQty3',
        'Service4Cost4',
        'Service4MoQ4',
        'Service4MaxQty4',
        'Service4Cost5',
        'Service4MoQ5',
        'Service4MaxQty5',
        'Service4Cost6',
        'Service4MoQ6',
        'Service4MaxQty6',
        'Service 5 NZ Rate',
        'Service5Cost1',
        'Service5MoQ1',
        'Service5MaxQty1',
        'Service5Cost2',
        'Service5MoQ2',
        'Service5MaxQty2',
        'Service5Cost3',
        'Service5MoQ3',
        'Service5MaxQty3',
        'Service5Cost4',
        'Service5MoQ4',
        'Service5MaxQty4',
        'Service5Cost5',
        'Service5MoQ5',
        'Service5MaxQty5',
        'Service5Cost6',
        'Service5MoQ6',
        'Service5MaxQty6',
        'OptionalService1Cost',
        'OptionalService1 NZ Rate',
        'OptionalService1MOQ',
        'OptionalService1MaxQty',
        'OptionalService2Cost',
        'OptionalService2 NZ Rate',
        'OptionalService2MOQ',
        'OptionalService2MaxQty',
        'OptionalService3Cost',
        'OptionalService3 NZ Rate',
        'OptionalService3MOQ',
        'OptionalService3MaxQty',
        'BlankPriceOSDes1 NZ Rate',
        'QtyOS1_1',
        'PriceOS1_1',
        'QtyOS1_2',
        'PriceOS1_2',
        'QtyOS1_3',
        'PriceOS1_3',
        'QtyOS1_4',
        'PriceOS1_4',
        'QtyOS1_5',
        'PriceOS1_5',
        'QtyOS1_6',
        'PriceOS1_6',
        'QtyOS1_7',
        'PriceOS1_7',
        'QtyOS1_8',
        'PriceOS1_8',
        'QtyOS1_9',
        'PriceOS1_9',
        'Service 6 NZ Rate',
        'Service6Cost1',
        'Service6MoQ1',
        'Service6MaxQty1',
        'Service6Cost2',
        'Service6MoQ2',
        'Service6MaxQty2',
        'Service6Cost3',
        'Service6MoQ3',
        'Service6MaxQty3',
        'Service6Cost4',
        'Service6MoQ4',
        'Service6MaxQty4',
        'Service6Cost5',
        'Service6MoQ5',
        'Service6MaxQty5',
        'Service6Cost6',
        'Service6MoQ6',
        'Service6MaxQty6',
        'BlankPriceOSDes2 NZ Rate',
        'QtyOS2_1',
        'PriceOS2_1',
        'QtyOS2_2',
        'PriceOS2_2',
        'QtyOS2_3',
        'PriceOS2_3',
        'QtyOS2_4',
        'PriceOS2_4',
        'QtyOS2_5',
        'PriceOS2_5',
        'QtyOS2_6',
        'PriceOS2_6',
        'QtyOS2_7',
        'PriceOS2_7',
        'QtyOS2_8',
        'PriceOS2_8',
        'QtyOS2_9',
        'PriceOS2_9',
        'Service 7 NZ Rate',
        'Service7Cost1',
        'Service7MoQ1',
        'Service7MaxQty1',
        'Service7Cost2',
        'Service7MoQ2',
        'Service7MaxQty2',
        'Service7Cost3',
        'Service7MoQ3',
        'Service7MaxQty3',
        'Service7Cost4',
        'Service7MoQ4',
        'Service7MaxQty4',
        'Service7Cost5',
        'Service7MoQ5',
        'Service7MaxQty5',
        'Service7Cost6',
        'Service7MoQ6',
        'Service7MaxQty6',
        'BlankPriceOSDes3 NZ Rate',
        'QtyOS3_1',
        'PriceOS3_1',
        'QtyOS3_2',
        'PriceOS3_2',
        'QtyOS3_3',
        'PriceOS3_3',
        'QtyOS3_4',
        'PriceOS3_4',
        'QtyOS3_5',
        'PriceOS3_5',
        'QtyOS3_6',
        'PriceOS3_6',
        'QtyOS3_7',
        'PriceOS3_7',
        'QtyOS3_8',
        'PriceOS3_8',
        'QtyOS3_9',
        'PriceOS3_9',
        'Service 8 NZ Rate',
        'Service8Cost1',
        'Service8MoQ1',
        'Service8MaxQty1',
        'Service8Cost2',
        'Service8MoQ2',
        'Service8MaxQty2',
        'Service8Cost3',
        'Service8MoQ3',
        'Service8MaxQty3',
        'Service8Cost4',
        'Service8MoQ4',
        'Service8MaxQty4',
        'Service8Cost5',
        'Service8MoQ5',
        'Service8MaxQty5',
        'Service8Cost6',
        'Service8MoQ6',
        'Service8MaxQty6',
        'BlankPriceOSDes4 NZ Rate',
        'QtyOS4_1',
        'PriceOS4_1',
        'QtyOS4_2',
        'PriceOS4_2',
        'QtyOS4_3',
        'PriceOS4_3',
        'QtyOS4_4',
        'PriceOS4_4',
        'QtyOS4_5',
        'PriceOS4_5',
        'QtyOS4_6',
        'PriceOS4_6',
        'QtyOS4_7',
        'PriceOS4_7',
        'QtyOS4_8',
        'PriceOS4_8',
        'QtyOS4_9',
        'PriceOS4_9',
        'Service 9 NZ Rate',
        'Service9Cost1',
        'Service9MoQ1',
        'Service9MaxQty1',
        'Service9Cost2',
        'Service9MoQ2',
        'Service9MaxQty2',
        'Service9Cost3',
        'Service9MoQ3',
        'Service9MaxQty3',
        'Service9Cost4',
        'Service9MoQ4',
        'Service9MaxQty4',
        'Service9Cost5',
        'Service9MoQ5',
        'Service9MaxQty5',
        'Service9Cost6',
        'Service9MoQ6',
        'Service9MaxQty6',
        'OptionalService4Cost',
        'OptionalService4 NZ Rate',
        'OptionalService4MoQ',
        'OptionalService4MaxQty',
        'OptionalService5Cost',
        'OptionalService5 NZ Rate',
        'OptionalService5MoQ',
        'OptionalService5MaxQty',
        'OptionalService6Cost',
        'OptionalService6 NZ Rate',
        'OptionalService6MoQ',
        'OptionalService6MaxQty',
        'New Setup Cost',
        'Repeat Setup Cost',
        'NZ Freight Rate',
        'NZ Setup Rate',
      ]
      booleanFieldList = [
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
        'Local 1 Is NZ Price Table',
        'Local 2 Is NZ Price Table',
        'Local 3 Is NZ Price Table',
        'BlankPriceOSDes1 Is NZ Price Table',
        'BlankPriceOSDes2 Is NZ Price Table',
        'BlankPriceOSDes3 Is NZ Price Table',
        'NZ Tag',
      ]
    } else if (name === 'usa') {
      requiredFieldList = [
        'Product Code',
        'Product Name',
        'Parent Category',
        'Child Category_1',
        'Product Description',
        'Product Additional Information',
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Product Photo File Names',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'New Setup Cost',
        'Repeat Setup Cost',
        'Price Disclaimer',
        'Freight Details',
      ]
      numberFieldList = [
        'Freight for 1st location',
        'Freight for Split Delivery',
        'Carton Length',
        'Carton Width',
        'Carton Height',
        'Qty per Carton',
        'CartonWeight',
        'QtyLocal1_1',
        'PriceLocal1_1',
        'QtyLocal1_2',
        'PriceLocal1_2',
        'QtyLocal1_3',
        'PriceLocal1_3',
        'QtyLocal1_4',
        'PriceLocal1_4',
        'QtyLocal1_5',
        'PriceLocal1_5',
        'QtyLocal1_6',
        'PriceLocal1_6',
        'QtyLocal1_7',
        'PriceLocal1_7',
        'QtyLocal1_8',
        'PriceLocal1_8',
        'QtyLocal1_9',
        'PriceLocal1_9',
        'QtyLocal2_1',
        'PriceLocal2_1',
        'QtyLocal2_2',
        'PriceLocal2_2',
        'QtyLocal2_3',
        'PriceLocal2_3',
        'QtyLocal2_4',
        'PriceLocal2_4',
        'QtyLocal2_5',
        'PriceLocal2_5',
        'QtyLocal2_6',
        'PriceLocal2_6',
        'QtyLocal2_7',
        'PriceLocal2_7',
        'QtyLocal2_8',
        'PriceLocal2_8',
        'QtyLocal2_9',
        'PriceLocal2_9',
        'QtyLocal3_1',
        'PriceLocal3_1',
        'QtyLocal3_2',
        'PriceLocal3_2',
        'QtyLocal3_3',
        'PriceLocal3_3',
        'QtyLocal3_4',
        'PriceLocal3_4',
        'QtyLocal3_5',
        'PriceLocal3_5',
        'QtyLocal3_6',
        'PriceLocal3_6',
        'QtyLocal3_7',
        'PriceLocal3_7',
        'QtyLocal3_8',
        'PriceLocal3_8',
        'QtyLocal3_9',
        'PriceLocal3_9',
        'Service1Cost1',
        'Service1MoQ1',
        'Service1MaxQty1',
        'Service1Cost2',
        'Service1MoQ2',
        'Service1MaxQty2',
        'Service1Cost3',
        'Service1MoQ3',
        'Service1MaxQty3',
        'Service1Cost4',
        'Service1MoQ4',
        'Service1MaxQty4',
        'Service1Cost5',
        'Service1MoQ5',
        'Service1MaxQty5',
        'Service1Cost6',
        'Service1MoQ6',
        'Service1MaxQty6',
        'Service2Cost1',
        'Service2MoQ1',
        'Service2MaxQty1',
        'Service2Cost2',
        'Service2MoQ2',
        'Service2MaxQty2',
        'Service2Cost3',
        'Service2MoQ3',
        'Service2MaxQty3',
        'Service2Cost4',
        'Service2MoQ4',
        'Service2MaxQty4',
        'Service2Cost5',
        'Service2MoQ5',
        'Service2MaxQty5',
        'Service2Cost6',
        'Service2MoQ6',
        'Service2MaxQty6',
        'Service3Cost1',
        'Service3MoQ1',
        'Service3MaxQty1',
        'Service3Cost2',
        'Service3MoQ2',
        'Service3MaxQty2',
        'Service3Cost3',
        'Service3MoQ3',
        'Service3MaxQty3',
        'Service3Cost4',
        'Service3MoQ4',
        'Service3MaxQty4',
        'Service3Cost5',
        'Service3MoQ5',
        'Service3MaxQty5',
        'Service3Cost6',
        'Service3MoQ6',
        'Service3MaxQty6',
        'Service4Cost1',
        'Service4MoQ1',
        'Service4MaxQty1',
        'Service4Cost2',
        'Service4MoQ2',
        'Service4MaxQty2',
        'Service4Cost3',
        'Service4MoQ3',
        'Service4MaxQty3',
        'Service4Cost4',
        'Service4MoQ4',
        'Service4MaxQty4',
        'Service4Cost5',
        'Service4MoQ5',
        'Service4MaxQty5',
        'Service4Cost6',
        'Service4MoQ6',
        'Service4MaxQty6',
        'OptionalService1Cost',
        'OptionalService1MOQ',
        'OptionalService1MaxQty',
        'OptionalService2Cost',
        'OptionalService2MOQ',
        'OptionalService2MaxQty',
        'OptionalService3Cost',
        'OptionalService3MOQ',
        'OptionalService3MaxQty',
        'QtyOS1_1',
        'PriceOS1_1',
        'QtyOS1_2',
        'PriceOS1_2',
        'QtyOS1_3',
        'PriceOS1_3',
        'QtyOS1_4',
        'PriceOS1_4',
        'QtyOS1_5',
        'PriceOS1_5',
        'QtyOS1_6',
        'PriceOS1_6',
        'QtyOS1_7',
        'PriceOS1_7',
        'QtyOS1_8',
        'PriceOS1_8',
        'QtyOS1_9',
        'PriceOS1_9',
        'Service5Cost1',
        'Service5MoQ1',
        'Service5MaxQty1',
        'Service5Cost2',
        'Service5MoQ2',
        'Service5MaxQty2',
        'Service5Cost3',
        'Service5MoQ3',
        'Service5MaxQty3',
        'Service5Cost4',
        'Service5MoQ4',
        'Service5MaxQty4',
        'Service5Cost5',
        'Service5MoQ5',
        'Service5MaxQty5',
        'Service5Cost6',
        'Service5MoQ6',
        'Service5MaxQty6',
        'QtyOS2_1',
        'PriceOS2_1',
        'QtyOS2_2',
        'PriceOS2_2',
        'QtyOS2_3',
        'PriceOS2_3',
        'QtyOS2_4',
        'PriceOS2_4',
        'QtyOS2_5',
        'PriceOS2_5',
        'QtyOS2_6',
        'PriceOS2_6',
        'QtyOS2_7',
        'PriceOS2_7',
        'QtyOS2_8',
        'PriceOS2_8',
        'QtyOS2_9',
        'PriceOS2_9',
        'Service6Cost1',
        'Service6MoQ1',
        'Service6MaxQty1',
        'Service6Cost2',
        'Service6MoQ2',
        'Service6MaxQty2',
        'Service6Cost3',
        'Service6MoQ3',
        'Service6MaxQty3',
        'Service6Cost4',
        'Service6MoQ4',
        'Service6MaxQty4',
        'Service6Cost5',
        'Service6MoQ5',
        'Service6MaxQty5',
        'Service6Cost6',
        'Service6MoQ6',
        'Service6MaxQty6',
        'QtyOS3_1',
        'PriceOS3_1',
        'QtyOS3_2',
        'PriceOS3_2',
        'QtyOS3_3',
        'PriceOS3_3',
        'QtyOS3_4',
        'PriceOS3_4',
        'QtyOS3_5',
        'PriceOS3_5',
        'QtyOS3_6',
        'PriceOS3_6',
        'QtyOS3_7',
        'PriceOS3_7',
        'QtyOS3_8',
        'PriceOS3_8',
        'QtyOS3_9',
        'PriceOS3_9',
        'Service7Cost1',
        'Service7MoQ1',
        'Service7MaxQty1',
        'Service7Cost2',
        'Service7MoQ2',
        'Service7MaxQty2',
        'Service7Cost3',
        'Service7MoQ3',
        'Service7MaxQty3',
        'Service7Cost4',
        'Service7MoQ4',
        'Service7MaxQty4',
        'Service7Cost5',
        'Service7MoQ5',
        'Service7MaxQty5',
        'Service7Cost6',
        'Service7MoQ6',
        'Service7MaxQty6',
        'OptionalService4Cost',
        'OptionalService4MoQ',
        'OptionalService4MaxQty',
        'OptionalService5Cost',
        'OptionalService5MoQ',
        'OptionalService5MaxQty',
        'OptionalService6Cost',
        'OptionalService6MoQ',
        'OptionalService6MaxQty',
        'New Setup Cost',
        'Repeat Setup Cost',
      ]
      booleanFieldList = [
        'FullColour Tag',
        'Featured Tag',
        'New Tag',
        'Eco Tag',
        'On Sale Tag',
        'Sourcing Tag',
        'Discontinued',
      ]
    }

    requiredFieldList = requiredFieldList.map((requireField) => {
      return {
        columnIndex: filerHeader.indexOf(requireField),
        required: true,
      }
    })

    numberFieldList = numberFieldList.map((numberField) => {
      return {
        columnIndex: filerHeader.indexOf(numberField),
        isNumber: true,
      }
    })

    booleanFieldList = booleanFieldList.map((booleanField) => {
      return {
        columnIndex: filerHeader.indexOf(booleanField),
        isBoolean: true,
      }
    })

    const checkColumns = mergeArraysByColumnIndex([
      requiredFieldList,
      numberFieldList,
      booleanFieldList,
    ])

    const errorList = []
    for (let i = 1; i < lines.length; i++) {
      const columns = lines[i]
      columns.forEach((element) => {
        if (element) {
          const invalidCharPattern =
            /[�\x00-\x08\x0B\x0C\x0E-\x1F\x7F\u0000-\u0008\u000B\u000C\u000E-\u001F\u007F-\u009F]/
          if (invalidCharPattern.test(element)) {
            errorList.push({
              title: `Row ${i + 1} - ${filerHeader[columns.indexOf(element)]}`,
              description: `Include invalid character`,
            })
          }
          if (
            filerHeader[columns.indexOf(element)] === 'Product Change Log' ||
            filerHeader[columns.indexOf(element)] === 'Product Change Log NZ'
          ) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(element, 'text/html')
            const ul = doc.querySelector('ul')
            const li = ul ? ul.querySelector('li') : null
            if (!ul || !li) {
              errorList.push({
                title: `Row ${i + 1} - ${
                  filerHeader[columns.indexOf(element)]
                }`,
                description: `Not in the <ul><li> format`,
              })
            }
          }
        }
      })
      for (let j = 0; j < checkColumns.length; j++) {
        const item = checkColumns[j]
        if (item.required) {
          if (!columns[item.columnIndex]) {
            errorList.push({
              title: `Row ${i + 1} - ${filerHeader[item.columnIndex]}`,
              description: `Missing value`,
            })
          } else {
            if (columns[item.columnIndex].trim() === '') {
              errorList.push({
                title: `Row ${i + 1} - ${filerHeader[item.columnIndex]}`,
                description: `Missing value`,
              })
            }
          }
        }
        if (
          item.isNumber &&
          columns[item.columnIndex] &&
          columns[item.columnIndex].trim() !== '' &&
          !/^-?\d+(\.\d+)?$/.test(columns[item.columnIndex].trim())
        ) {
          errorList.push({
            title: `Row ${i + 1} - ${filerHeader[item.columnIndex]}`,
            description: `Value is not a number`,
          })
        }
        if (
          item.isBoolean &&
          columns[item.columnIndex] &&
          columns[item.columnIndex].trim() !== '' &&
          !/^(Y|N)$/.test(columns[item.columnIndex].trim())
        ) {
          errorList.push({
            title: `Row ${i + 1} - ${filerHeader[item.columnIndex]}`,
            description: `Value is not a Y or N`,
          })
        }
      }
    }
    return errorList
  }

  /**
   * Merges an array of objects by their columnIndex property.
   *
   * @param {Array} arrays - An array of arrays of objects with a columnIndex property.
   * @return {Array} An array of merged objects.
   */
  const mergeArraysByColumnIndex = (arrays) => {
    const merged = arrays.flat().reduce((acc, item) => {
      // If the accumulator doesn't have an object at the current columnIndex,
      // add the current item
      if (!acc[item.columnIndex]) {
        acc[item.columnIndex] = { ...item }
      } else {
        // If the accumulator already has an object at the current columnIndex,
        // merge the current item with the existing object
        acc[item.columnIndex] = { ...acc[item.columnIndex], ...item }
      }
      return acc
    }, {})
    return Object.values(merged)
  }

  /**
   * Downloads the given header and rows as a zipped CSV file.
   *
   * @param {Array} header - The header row to be included in the CSV file.
   * @param {Array} rows - The rows of data to be included in the CSV file.
   * @return {void} This function does not return anything.
   */
  const downloadFile = (header, rows, name) => {
    // Define the chunk size for splitting the rows into multiple CSV files
    const chunkSize = 200
    // Calculate the number of chunks needed to split the rows
    const numChunks = Math.ceil(rows.length / chunkSize)

    const zip = new JSZip()

    for (let i = 0; i < numChunks; i++) {
      const chunkStart = i * chunkSize
      const chunkEnd = (i + 1) * chunkSize
      // Extract the current chunk of rows
      const chunkRows = rows.slice(chunkStart, chunkEnd)
      // Initialize the CSV content string with the header row
      let csvContent = '"' + header.join('","') + '"\n'
      // Loop through each row in the current chunk
      chunkRows.forEach((row) => {
        // Convert each cell in the row to a CSV string and join them with commas
        csvContent +=
          '"' +
          row
            .map((cell) => {
              if (typeof cell === 'string') {
                // Escape any double quotes in the cell value
                return cell.replace(/"/g, '""')
              } else {
                return cell
              }
            })
            .join('","') +
          '"\n'
      })
      // Add the CSV content to the JSZip instance with a filename based on the chunk index
      zip.file(`dex_product_${i + 1}.csv`, csvContent)
    }

    // Generate a Blob from the JSZip instance and download it as a zip file
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, 'dex_products.zip')
    })

    // Set the success state to true
    if (name === 'au') {
      setAuSuccess(true)
    } else if (name === 'usa') {
      setUsaSuccess(true)
    }
  }

  return (
    <>
      <div className="flex flex-col w-full gap-3 px-2 mx-auto my-4 lg:flex-row lg:w-3/4">
        <Instruction
          heading="Import Product Data File Instructions"
          fileName="Dex-All-Export-One"
          standardFile="https://staticstaging.dexcollection.com.au/app/uploads/2024/07/dex-import-file-field-rules.csv"
        ></Instruction>
        <div className="flex flex-col flex-shrink-0 gap-3 lg:w-1/2">
          <div className="flex items-center justify-center">
            <label
              htmlFor="au-file"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  CSV only
                </p>
              </div>
              <input
                id="au-file"
                type="file"
                name="au"
                className="hidden"
                onChange={onFileChange}
              />
            </label>
          </div>
          {auErrorList.length > 0 &&
            auErrorList.map((error, index) => (
              <div
                key={index}
                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <span className="font-bold">{error.title}:</span>{' '}
                {error.description}
              </div>
            ))}
          {auSuccess && (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <span className="font-medium">Success, File is valid</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full gap-3 px-2 mx-auto my-4 mt-10 lg:flex-row lg:w-3/4">
        <Instruction
          heading="Import USA Product Data File Instructions"
          fileName="Dex-All-Export-USA"
          standardFile=""
        ></Instruction>
        <div className="flex flex-col flex-shrink-0 gap-3 lg:w-1/2">
          <div className="flex items-center justify-center">
            <label
              htmlFor="usa-file"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  CSV only
                </p>
              </div>
              <input
                id="usa-file"
                type="file"
                name="usa"
                className="hidden"
                onChange={onFileChange}
              />
            </label>
          </div>
          {usaErrorList.length > 0 &&
            usaErrorList.map((error, index) => (
              <div
                key={index}
                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <span className="font-bold">{error.title}:</span>{' '}
                {error.description}
              </div>
            ))}
          {usaSuccess && (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <span className="font-medium">Success, File is valid</span>
            </div>
          )}
        </div>
      </div>
      {loading && <Loading></Loading>}
    </>
  )
}

export default App
